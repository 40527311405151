import React from "react";
import styles from "./hero-section.module.css";

export default function HeroSection({ videoUrl }) {
  return (
    <>
      <div className={styles.sectionWrapper}>
        <div className={styles.textColumn}>
          <div className={styles.heading}>
            THE FUTURE OF PLUMBING.{" "}
            <span className={styles.greenText}>FAST, EASY & VERSATILE.</span>
          </div>
          <h1 className={styles.subheading}>South Africa's Choice.</h1>
          <div className={styles.descText}>
            The Unitwist family of products are a household name within the
            South African plumbing sector. With over 20 years of manufacturing
            innovative products, designed to solve complex problems faced by
            plumbers and DIY’ers alike, we have grown to become the leading
            independent system in South Africa.
          </div>
        </div>
        <div className={styles.videoColumn}>
          <iframe
            loading="lazy"
            className={styles.styledIframe}
            src={`https://player.vimeo.com/video/${videoUrl}?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479`}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
            title="BEHIND_THE_TECHNOLOGY_-_UNITWIST_ENGLISH_lvsomu"
          />
        </div>
      </div>
    </>
  );
}
