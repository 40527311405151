import React from "react";
import Carousel from "react-multi-carousel";
import "./ImageSequence.css";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
const ImageCarousel = ({ images }) => {
  return (
    <Carousel
      responsive={responsive}
      ssr // server-side rendering
      infinite
      autoPlay
      autoPlaySpeed={3000}
      keyBoardControl
      containerClass="carousel-container"
      itemClass="carousel-item-padding-40-px"
    >
      {images.map((url, index) => (
        <div key={index} className="image-item">
          <img
            loading="lazy"
            src={url}
            alt={`Slide ${index}`}
            style={{ width: "100%" }}
          />
        </div>
      ))}
    </Carousel>
  );
};
export default ImageCarousel;
